.main {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 90%;
}

.col {
  flex-basis: 50%;
}

.titleRow {
  flex-basis: 100%;
  text-align: center;
}
